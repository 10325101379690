<template>
  <!--span v-if="name" :style="{ color: color }">
    {{ name }}
  </span-->
  <v-chip
    v-if="name"
    class="ma-2"
    label
    @click="onClick(item.PresentationID)"
    :color="color"
  >
    {{name}}
  </v-chip>
</template>
<script>
import api from '@/services/api'
export default {
  props: {
    item: {
      type: Object,
      requried: true,
    },
    onClick: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    name: null,
  }),
  computed: {
    environment () {
      return 'players'
    },
  },
  mounted () {
    if (!this.item.Presentation) {
      this.color = 'red'
      this.handleGetPresentationByID(this.item.PresentationID)
    } else {
      this.color = null
      this.name = this.item.Presentation
    }
  },
  methods: {
    handleGetPresentationByID (v) {
      const url = `v1/private/presentations/`
      api.getItem (this.environment, url, v)
        .then(response => {
          this.name = response ? `${response.Name} [${response.WorkspaceName}]` : v
        })
        .catch(() => {
          this.name = v
        })
    },
  },
}
</script>
